.kmc-console-manageadmin-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-manageadmin-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}


.kmc-console-manageadmin-form-box {
    margin-top: 1.5rem;
}

.kmc-console-manageadmin-selecteduser-facade {
    border: 1px solid #b5d4f1;
}

.kmc-console-manageadmin-selecteduser-facade:hover {
    box-shadow: -1px 5px 10px 0px rgba(82, 82, 140, 0.471);
}