.kmc-console-header-settings-button {
    width: 165px !important;   
    background-color: rgba(241, 241, 245, 0.1) !important;
    color: #fff !important;
    text-transform: none !important;
    border-radius: 8px !important;
    font-family: 'Open Sans' !important;
    font-weight: 550 !important;
    font-size: 14px !important;
    margin-top: 0.5rem !important;
}