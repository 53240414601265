.kmc-console-managedocumentschecklist-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-managedocumentschecklist-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-managedocumentschecklist-form-box {
    margin: 1.5rem;
}

.kmc-console-managedocumentschecklist-group-header-title .MuiCardHeader-title {
    font-size: 16px;
    text-transform: uppercase;
}

.kmc-console-managedocumentschecklist-group-header-title {
    background: aliceblue !important;
}