.kmc-console-home-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-home-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-emp-homestore-facade {
    border: 1px solid #b5d4f1;
    cursor: pointer;
}

.kmc-console-emp-homestore-facade .MuiCardHeader-content>span {
    font-size: medium !important;
}

.kmc-console-emp-homestore-facade:hover {
    box-shadow: -1px 10px 25px 0px rgba(82, 82, 140, 0.471);
}