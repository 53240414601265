.kmc-console-offboard-info-card {
  text-align: center;
  background-color: #fff;
  border: none;
  box-shadow: #3a87f626 0 5px 15px;
  padding: 1rem !important;
  margin: 1rem !important;
  box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-offboard-content-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem 1rem 1rem !important;
  margin: 0 1rem 1rem 1rem !important;
  box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-offboard-form-box {
  margin: 1.5rem;
}

.kmc-console-swiperslide-card {
  border: 1px solid #c5bebe;
  cursor: pointer;
  background-color: #fff !important;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.kmc-console-swiperslide-card:hover {
  box-shadow: -1px 5px 25px 0px rgba(0, 0, 0, 0.8);
}

.kmc-console-swiperslide-card-header .MuiCardHeader-content > span {
  font-size: small !important;
}
@media (max-width: 720px) {
  .kmc-console-swiperslide-card {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-wrapper {
    border: 1px solid #c5bebe!important;
  }
  .kmc-console-swiperslide-card {
    border: none !important;
  }
}
