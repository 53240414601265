.kmc-console-branding-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-branding-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-branding-img-preview {
    /* border-radius: 50%; */
    aspect-ratio: attr(width) / attr(height) !important;
}

.kmc-console-branding-color-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.kmc-console-branding-color-image-box {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    width: 100% !important;
}

.kmc-console-branding-color-picker-text {
    font-size: 1rem !important;
    width: 220px !important;
    padding: 6px 12px;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
}

.kmc-console-branding-color-group {
    display: flex;
    align-items: center;
    width: 100%;
}

.w-color-sketch {
    margin: 0 !important;
    display: block;
    visibility: visible;
    width: 230px;
    top: 0px;
    left: -162.203px;
}

.kmc-console-branding-color-selection-box {
    width: 100%;
}

.kmc-console-branding-logo-preview-box {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    width: 100% !important;
}

.kmc-console-branding-logo-selection-box {
    width: 100%;
}

.kmc-console-branding-logo-preview-box .kmc-console-branding-logo-card-header {
    background-image: linear-gradient(to bottom, var(--themeblue) 30%, transparent) !important;
    --themeblue: rgb(164, 168, 167) !important;    
}