.kmc-console-app-main {
    display: flex;
    width: 100%;
}

.kmc-console-app-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh !important;
    min-width: 0 !important;
}

.kmc-console-app-main-sidebar {
    position: relative !important;
    height: 100vh !important;
}

@media (max-width:1024px) {
    
    .kmc-console-app-main-sidebar {
        display: none;
    }

    .kmc-console-app-main-sidebar-mobile {
        display: block;
        position: fixed;
        top: 0;
        height: 100% !important;
        width: 100% !important;
        z-index: 90 !important;
    }

    .MuiDrawer-paperAnchorDockedLeft {
        overscroll-behavior: none !important;
    }

    .menu-bar {
        display: block !important;
    }

    .menu-bar-btn {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        z-index: 99 !important;
        padding: 0 !important;
        margin: 15px !important;
    }

    .main-head {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .head-root {
        padding: 15px !important;
    }

    .img-size {
        width: 30% !important;
    }

    .head-left-align {
        display: contents !important;
    }

    .tool-bar {
        display: block !important;
    }

    .list-content {
        padding: 10px 0px !important;
    }

    .head-left-content {
        padding: 0px 20px !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .settings-btn-hlgt {
        margin: 15px 10px !important;
    }
}

@media(min-width:1023px) {
    .menu-bar {
        display: none !important;
    }

    .kmc-console-app-main-sidebar {
        display: block;
    }

    .kmc-console-app-main-sidebar-mobile {
        display: none;
    }

    .menu-bar-btn {
        display: none !important;
    }

    .tool-bar {
        height: 40px !important;
    }
}