.kmc-console-edit-employee-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-edit-employee-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-edit-employee-form-box {
    margin: 1.5rem;
}


.kmc-console-edit-profile-content-logo-name-text{
    display: flex !important;
    border: none !important;
}
.kmc-console-edit-profile-logo-name-text .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin-bottom: 10px !important;
}
.kmc-console-edit-profile-carousel-preview .carousel.carousel-slider,.kmc-console-edit-profile-carousel-preview{
    
    width: 480px !important;
}
.kmc-console-edit-profile-content-row{
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

.kmc-console-edit-profile-carousel-text{
    width: 480px ;
    text-align: left;
}
.kmc-console-edit-profile-content-row .css-bsph2u-MuiTableCell-root{
    border: none !important;
}
.kmc-console-edit-profile-content-row .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
    width: 100% !important;
}
.kmc-console-edit-profile-content-check .css-bsph2u-MuiTableCell-root {
    border: none !important;
}
.kmc-console-userpicker-box{
    margin-top: 5px;
}