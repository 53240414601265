.kmc-console-payment-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-payment-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-payment-content-cc-logo-button {
    height: 60px !important;    
    flex-direction: column !important;
    justify-content: space-evenly !important;
    font-size: 10px !important;    
    font-weight: 550 !important;
    background-color: #4c6783 !important;
}

.kmc-console-payment-cc-form-box {
   margin: 1.5rem;
}

