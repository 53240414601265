.kmc-console-onboard-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-onboard-orgchart-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
    overflow: auto !important;
    position: relative !important;
}

.kmc-console-onboard-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-onboard-cc-form-box {
    margin: 1.5rem;
}

.kmc-console-onboard-tree-node {
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    background-color: #93977e26;
}

.kmc-console-onboard-tree-child-node {
    padding: 5px !important;
    border-radius: 8px !important;
    display: inline-block !important;
    background-color: #bfcd6f26 !important;
}

.kmc-console-manager-display-card {
    border: 1px solid #c5bebe;
    cursor: pointer;
    background-color: #fff !important;
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.kmc-console-manager-display-card:hover {
    box-shadow: -1px 5px 25px 0px rgba(0, 0, 0, 0.8);
}