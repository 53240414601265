.kmc-console-plandetails-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-plandetails-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-plandetails-billing-content-box {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.kmc-console-plandetails-billing-content-box .content-box {
    display: flex !important;
    flex-direction: column !important;
    width: 95% !important;
    height: 80% !important;
    background-image: linear-gradient(to bottom, var(--themeblue) 30%, transparent) !important;
    --themeblue: rgb(195, 213, 218);
    --themedarkblue: rgb(58, 135, 246);
    --themepurple: rgb(50, 48, 81);
    padding: 32px !important;
}

.kmc-console-plandetails-billing-content-box .content-box.selected-plan {
    background-image: linear-gradient(to bottom, var(--themeblue) 30%, transparent) !important;
    --themeblue: rgb(40, 133, 114) !important;
}


.kmc-console-plandetails-billing-content-box .billing-text-title {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #f9f6f6 !important;
    margin-bottom: 12px !important;
    text-align: center;
}

.kmc-console-plandetails-billing-content-box .billing-text-descr {
    font-size: 22px !important;
    font-weight: 500 !important;
    color: #212529 !important;
    margin-bottom: 12px !important;
    text-align: left !important;
    line-height: 1.5 !important;
    display: list-item
}

.kmc-console-plandetails-billing-content-box .select-btn {
    border-radius: 100px !important;
    color: #0dcaf0 !important;
    padding: 6px 20px !important;
    border: 1px solid #0dcaf0 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    width: fit-content !important;
}

.kmc-console-plandetails-billing-content-box .amount {
    font-weight: 900 !important;
    font-size: 20px !important;
    text-align: center !important;
    margin: 4px 0px 12px 0px !important;
}

.kmc-console-plandetails-billing-content-box .continue-btn {
    width: fit-content !important;
    margin: 20px 0px !important;
    text-transform: capitalize !important;
    font-size: 1rem !important;
    color: #000000 !important;
    background-color: rgb(34, 148, 173) !important;
    border-radius: 0.25rem !important;
    font-weight: 400 !important;
}

.image {
    width: 12rem;
    /* Adjust according to your image size */
    height: 12rem;
    /* Adjust according to your image size */
    border-radius: 50%;
    /* Creates a round shape */
    background-color: white;
    /* Replace with your image or color */
    margin-right: 1rem;
    /* Adjust spacing between image and text */
}

.col-6 {
    flex: 1;
    /* Equal width for both columns */
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.mothly-text{
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #0dcaf0 !important;
}