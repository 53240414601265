#root,
html,
body,
.kmc-customheight100 {
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
}
.kmc-customheight100{
  width: 100%;
  height: 100%;
  background:linear-gradient(98deg, rgba(59,130,246,1) 0%, rgba(34,211,238,1) 100%);
  /* background-image: url(../../assets/Loginbg.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center; */
  /* background: linear-gradient(to right, #2c3e50, #4ca1af); */
}

.css-nosrnw {
  margin: 0px !important;
}
html {
  height: 100% !important;
}
.kmc-loginbox {
  justify-content: center;
  align-items: center;
  height: 100% !important;
  padding: 0px 50px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  /* position: absolute;
  top: 0;
  left: 0; */
}
.kmc-customdiv {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: #3a87f626 0 5px 15px !important;
  z-index: 99;
}
.kmc-console-Login-background-image {
  height: 100%;
}
.kmc-loginflex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.kmc-loginbg {
  width: 600px;
  height: 600px;
  object-fit: contain;
  padding: 0px !important;
}
.kmc-customheight100 {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}
.kmc-loginvector {
  position: absolute;
  width: 200px;
  height: 200px;
  object-fit: contain;
  top: 0;
  right: 0;
}
.kmc-registerbtn {
  text-decoration: none !important;
}
.kmc-submitbtn {
  text-transform: capitalize !important;
  background-color: #1c243c !important;
}
.kms-logininput .MuiOutlinedInput-notchedOutline {
  border-color: #1c243c !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #1c243c !important;
}
@media (max-width:1024px) {
  .kmc-console-login-background-box{
    display: none!important;
  }
  .kmc-loginbox{
    padding: 0px 15px !important;
  }
}


