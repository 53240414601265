.kmc-console-emp-datastore-info-card {
    text-align: center;
    background-color: #fff;
    border: none;
    box-shadow: #3a87f626 0 5px 15px;
    padding: 1rem !important;
    margin: 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-emp-datastore-content-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem 1rem 1rem !important;
    margin: 0 1rem 1rem 1rem !important;
    box-shadow: #3a87f626 0 5px 15px !important;
}

.kmc-console-emp-datastore-facade {
    border: 1px solid #a4abc0;
    cursor: pointer;
}

.kmc-console-emp-datastore-facade .MuiCardHeader-content > span {
    font-size: medium !important;
}

.kmc-console-emp-datastore-facade:hover {
    box-shadow: -1px 5px 25px 0px rgba(0,0,0,0.8);
}

.kmc-console-emp-datastore-group-title {
    font-size: 1.40rem !important;
    color: #595a5f !important; 
    font-weight: 500 !important;
}
