.kmc-console-sidebar-drawer.MuiDrawer-root {
    width: 260px !important;
    height: 100vh !important;
}

.kmc-console-sidebar-drawer .MuiDrawer-paperAnchorDockedLeft {
    position: relative !important;
    width: 100% !important;

}

.kmc-console-sidebar-main-head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.kmc-console-sidebar-main-head .kmc-console-sidebar-main-head-root {
    padding: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.kmc-console-sidebar-main-head .kmc-console-logo {
    width: 20% !important;
}

.kmc-console-sidebar-main-head .kmc-console-logo-box {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-left: 20px;
}

.kmc-console-sidebar-main-head .kmc-console-logo-text {
    text-transform: capitalize;
    font-weight: 1000 !important;
    font-size: 25px !important;
    font-family: 'Poppins';
}

.kmc-console-sidebar-buttons {
    padding: 10px 15px;
    width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kmc-console-sidebar-buttons .kmc-console-sidebar-edit-btn {
    width: 106.54px;
    height: 40px;
    background-color: #d9d4dd !important;
    border-radius: 4px !important;
    color: #000000 !important;
    
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: none !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12) !important;
}

.kmc-console-sidebar-buttons .kmc-console-sidebar-edit-btn-active {
    width: 106.54px;
    height: 40px;
    background-color: #1C243C;
    border-radius: 4px !important;
    color: #ffffff !important;
    
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: none !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12) !important;
}

.kmc-console-sidebar-buttons .kmc-console-sidebar-edit-btn-active:hover {
    background-color: #1C243C !important
}

.kmc-console-sidebar-buttons .kmc-console-sidebar-settings-btn-active:hover {
    background-color: #1C243C !important
}

.kmc-console-sidebar-buttons .kmc-console-sidebar-settings-btn {
    width: 103.46px;
    height: 40px;
    border-radius: 4px !important;
    color: #000000 !important;
    background-color: #d9d4dd !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12) !important;
    text-transform: none !important;
}

.kmc-console-sidebar-buttons .kmc-console-sidebar-settings-btn-active {
    width: 103.46px;
    height: 40px;
    border-radius: 4px !important;
    color: #ffffff !important;  
    background-color: #1C243C;       
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12) !important;
    text-transform: none !important;
}



.kmc-console-sidebar-group .kmc-console-workspace-group .kmc-console-workspace-group-dropdown-icon {
    color: linear-gradient(98deg, rgba(59,130,246,1) 0%, rgba(34,211,238,1) 100%) !important;
    padding-left: 5px;
}

.kmc-console-workspace-group-li-text {
    color: linear-gradient(98deg, rgba(59,130,246,1) 0%, rgba(34,211,238,1) 100%) !important;
}

.kmc-console-tenant-details-litem-active {
    background-color: #cdd1dc !important;
    border-right: 0.2rem solid #1C243C !important
}

@media (max-width:1024px) {
    .kmc-console-sidebar-drawer.MuiDrawer-root {
        width: 100% !important;
        height: 100vh !important;
    }
}